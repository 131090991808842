.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import '~antd/dist/antd.css';

.spin_loader {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.container-results{
  min-height: calc(100vh - 56px - 72px) !important;
}

.card{
  border-color: #ffff !important; 
}

.div-error{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.div-error span{
  display: block;
  width: 100%;
  text-align: center;
}

.div-error p{
  text-align: center;
  font-size: large;
  font-family: sans-serif;
}

.img-error{
  height: 300px;
  text-align: 'center';
}

.footer{
  padding: 20px;
  margin-top: 10px;
  background-color: #282c34;
  text-align: center;
  width: 100%;
  bottom: 0;
}

.footer h5{
  color: aliceblue;
}
